.profile-grid{
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 2;
}
.profile-grid-temp{
    margin: 5px;
}
.profile-img{
    position: relative;
    width: 12rem;
    height: 12rem;
    cursor: pointer;
    border-radius: 50%;
}

.overlay {
    border-radius: 50%;
    position: absolute;
    top: 25px;
    width: 12rem;
    height: 12rem;
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0;
    transition: opacity 0.3s ease;
    cursor: pointer;
  }
  
  .nav-profile-image:hover .overlay {
    opacity: 1; /* show overlay on hover */
  }



  
@media (max-width: 768px) {
    .profile-grid{
        grid-template-columns:none;
        gap: 2;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {

}