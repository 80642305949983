.places-list {
  cursor: pointer;
  padding: 1rem;
}
.places-list:hover {
  background-color: #f8f7f7;
}
.autocomplete-container {
  position: relative;
}

.autocomplete-dropdown {
  position: absolute;
  width: auto;
  box-sizing: border-box;
  z-index: 1000;
}

