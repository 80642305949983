body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-control,
.btn {
  box-shadow: none !important;
  outline: none !important;
}
.form-control:focus,
.btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.custom-card-border {
  border-left: 1px solid #e1e4ee;
  border-right: 1px solid #e1e4ee;
}

.PhoneInput {
  height: 3.5rem;
  border: 1.567px solid #c4c4c4;
  border-radius: 4px;
  font-size: 1rem;

}


.PhoneInput:focus-within{
  border: 2px solid #0d6efd !important;
  
}

.disabledCursor{
  cursor: not-allowed;
}

   /* Change the checkbox color to blue */
   .form-check-input[type="checkbox"] {
  border-color: #2653d4;
  cursor: pointer;
  }

  /* Optional: Change the color of the checkmark */
  .form-check-input[type="checkbox"]:checked {
    background-color: #2e59d9;
    border-color: #2653d4;
    box-shadow: 0 0 0 0.2rem rgba(105, 136, 228, 0.5);
  }

.card-img-absolute{
  user-select: none !important;
}