.company-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1;
}
.reciept-info {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1;
}
.address-info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2;
}

table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  text-align: left;
}
